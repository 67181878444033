import React from 'react';
import { deviceWidth } from '@/utils/deviceWidth';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { returnSpecificImageUrl } from '../../../utils/returnSpecificImageUrl';
import { useSSRSelector } from '@/redux/ssrStore';

export const Card1 = ({ widgetConfig, category, key, onClick, horizontal }) => {
  // const { deviceWidth } = IS_SERVER ? { deviceWidth: 0 } : getDeviceInfo();

  // const style = {
  //   height: widgetConfig.card_cta_visibility
  //     ? deviceWidth < 900
  //       ? '400px'
  //       : widgetConfig?.image_dimension?.height
  //     : widgetConfig?.image_dimension?.height,
  //   minWidth: widgetConfig.card_cta_visibility
  //     ? deviceWidth < 900
  //       ? '300px'
  //       : '750px'
  //     : '300px',
  // };
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <article
      className="category-card-1"
      key={key}
      // style={style}
      onClick={() => onClick(category)}
    >
      <div
        style={{
          backgroundImage: `url(${returnSpecificImageUrl({
            theme,
            item: category,
          })})`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
          backgroundSize: 'cover',
        }}
        className="image"
      ></div>
      <p className="category-name tc">
        {category.name ? category.name : 'Available Items'}
      </p>
      {widgetConfig?.card_cta_visibility &&
        (!horizontal ? (
          <button className="category-btn db-ns dn">
            {theme?.cta_config?.banner_cta}
          </button>
        ) : (
          <button className="horizontal-category-btn db-ns dn">
            {getCustomCtaText('widget_cta')}
          </button>
        ))}
    </article>
  );
};

export const Card3 = ({ widgetConfig, category, key, onClick }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <article
      className="category-card-3"
      key={key}
      // style={{ height: widgetConfig.image_dimension.height }}
      style={{ height: 'auto' }}
      onClick={() => onClick(category)}
    >
      <img src={returnSpecificImageUrl({ theme, item: category })} />
      {widgetConfig?.card_cta_visibility && (
        <button className="category-btn">
          {category.name ? category.name : 'Available Items'}
        </button>
      )}
    </article>
  );
};

export const Card7 = ({ widgetConfig, category, cardIndx, onClick, skewDirection }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  const skewAngle = deviceWidth > 990 ? 15 : 5;
  const skew = {
    right: `skew(${skewAngle}deg)`,
    left: `skew(-${skewAngle}deg)`,
  };
  return (
    <article
      className="category-card-7"
      key={cardIndx}
      style={{
        // height: widgetConfig.image_dimension.height,
        transform: skew[skewDirection],
      }}
      onClick={() => onClick(category)}
    >
      <div
        style={{
          backgroundImage: `url(${returnSpecificImageUrl({
            theme,
            item: category,
          })})`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
          backgroundSize: 'cover',
        }}
      ></div>
      {widgetConfig?.card_cta_visibility && (
        <button className="category-btn ">
          {category.name ? category.name : 'Available Items'}
        </button>
      )}
    </article>
  );
};

export const Card9 = ({ widgetConfig, category, key, onClick, horizontal }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <article className="category-card-9" key={key} onClick={() => onClick(category)}>
      <div
        style={{
          backgroundImage: `url(${returnSpecificImageUrl({
            theme,
            item: category,
          })})`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
        }}
        className="image"
      ></div>
      <p className="category-name tc">
        {category.name ? category.name : 'Available Items'}
      </p>
      {widgetConfig?.card_cta_visibility &&
        (!horizontal ? (
          <button className="category-btn db-ns dn">Explore</button>
        ) : (
          <button className="horizontal-category-btn db-ns dn">Explore</button>
        ))}
    </article>
  );
};

export const Card11 = ({ widgetConfig, category, key, onClick, horizontal }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <div
      className={`category-card-11 ${horizontal ? 'horizontal-card' : ''}`}
      key={key}
      onClick={() => onClick(category)}
    >
      <div
        style={{
          backgroundImage: `url(${returnSpecificImageUrl({
            theme,
            item: category,
          })})`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
        className="image"
      ></div>
      <p className="category-name tc mv0">
        {category.name ? category.name : 'Available Items'}
      </p>
    </div>
  );
};

export const Card12 = ({ widgetConfig, category, key, onClick, horizontal }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <div
      className={`category-card-12 ${horizontal ? 'horizontal-card' : ''}`}
      key={key}
      onClick={() => onClick(category)}
    >
      <div
        style={{
          backgroundImage: `url(${returnSpecificImageUrl({
            theme,
            item: category,
          })})`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
        className="image"
      ></div>
      <p className="category-name tc mv0">
        {category.name ? category.name : 'Available Items'}
      </p>
    </div>
  );
};

export const Card13 = ({ widgetConfig, category, key, onClick, horizontal }) => {
  const { theme } = useSSRSelector((state) => state.storeReducer.store);

  return (
    <div
      className={`category-card-13 ${horizontal ? 'horizontal-card' : ``}`}
      key={key}
      onClick={() => onClick(category)}
    >
      <div className="relative w-100">
        <div
          style={{
            backgroundImage: `url(${returnSpecificImageUrl({
              theme,
              item: category,
            })})`,
            paddingTop: `${widgetConfig?.aspect_ratio}%`,
          }}
          className="image"
        ></div>
        <p
          className="category-name tc mv0 z-5"
          style={{
            paddingTop: `calc(${widgetConfig?.aspect_ratio}% - 29px)`,
          }}
        >
          {category.name ? category.name : 'Available Items'}
        </p>
      </div>
    </div>
  );
};
